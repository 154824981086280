import $ from 'jquery';
import { ToggleSet } from 'App/form/toggle-set';

import moment from 'moment';
import templayed from 'templayed';

var $form;
var dependantTemplate;

var module = {

	init: function ($elm) {
		$form = $elm;
		dependantTemplate = $('#template-dependant').html();

		module._initEvents();
	},

	_initEvents: function () {
		$form.on('click', '.js-add-dependant', module._addNewDependant);
		$form.on('click', '.js-remove-dependant', module._removeDependant);
	},

	// Retrieve fields info from variable created on QuestionnairePage.cshtml
	// Find existing dependants and count
	// Create newData variable used to populate JS html template
	// Loop through each field in the data variable
	// Update the field details with new id and hasHelp converted to boolean
	// Compile JS html template and insert after last dependant
	// Bind the datepicker and toggle for the new dependant
	_addNewDependant: function (event) {
		event.preventDefault();

		var data = window.dependantFields;
		var $existingDependants = $form.find('.js-dependant');
		var dependantCount = $existingDependants.length;

		var newData = {
			item: dependantCount + 1,
			uniqueValue: 28,
			hiddenId: '',
			date: [],
			mainRadios: [],
			hiddenRadios: []
		};

		for (var i = 0; i < data.length; i++) {
			var thisField = data[i];
			var newField = {
				id: thisField.id + newData.item,
				label: thisField.label,
				hasHelp: thisField.hasHelp === 'True',
				help: thisField.help,
				toggleController: '',
				hasSupportingMessage: !!thisField.supportingMessage,
				supportingMessage: thisField.supportingMessage
			};

			if (thisField.toggleController !== '') {
				newField.toggleController = thisField.toggleController + newData.item;
			}

			// Insert field into appropriate property of newData variable
			// hiddenId is first field
			// Date is second field
			// Next 2 fields are the visible radio buttons
			// Other fields are the hidden radio buttons
			if (i === 0) {
				newData.hiddenId = newField.id;
			} else if (i === 1) {
				newData.date.push(newField);
			} else if (i < 4) {
				newData.mainRadios.push(newField);
			} else {
				newData.hiddenRadios.push(newField);
			}
		}

		var compiledTemplate = templayed(dependantTemplate)(newData);

		$existingDependants.last().after(compiledTemplate);
		module._bindNewDependant();
		module._updateRemoveButtonDisplay(dependantCount + 1);
	},

	// Find the last dependant and its datepicker and toggle controllers
	// Bind new datepicker
	// Bind events for toggle controllers
	_bindNewDependant: function () {
		var $newDependant = $('.js-dependant').last();
		var $newControllers = $newDependant.find('[data-toggle-controller]');
		
		$newControllers.on('change', 'input', ToggleSet.toggleController);
	},

	_removeDependant: function () {
		event.preventDefault();

		var $existingDependants = $form.find('.js-dependant');
		var dependantCount = $existingDependants.length;

		$existingDependants.last().empty().remove();
		module._updateRemoveButtonDisplay(dependantCount - 1);
	},

	// Toggle display of 'remove dependant' button
	_updateRemoveButtonDisplay: function (dependantCount) {
		var action = 'addClass';

		if (dependantCount <= 1) {
			action = 'removeClass';
		}
		$('.js-remove-dependant')[action]('is-displayed');
	}
};

var Dependant = {
	init: module.init
};

export { Dependant };