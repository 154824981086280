import $ from 'jquery';
import { subscribe, unsubscribe, publish } from 'Util/pubsub';

import { ToggleSet } from 'App/form/toggle-set';
import { Dependant } from 'App/form/dependant';
import { Validate } from 'App/form/validate';

import moment from 'moment';

var $questionnaire;

function Person(id, dob, pr, mcr, mwl, mad, mto, msc, mtc) {
	this.PersonId = parseInt(id, 10);
	this.DateOfBirth = dob;
	this.PermanentResident = pr;
	this.MedicalCoverRequired = mcr;
	this.MedicalWaitingList = mwl;
	this.MedicalAwaitingDiagnosis = mad;
	this.MedicalTreatmentOverseas = mto;
	this.MedicalScreeningComplete = msc;
	this.MedicalTerminalCondition = mtc;
}

var selectors = Object.freeze({
	spendTrigger: '.js-spend-toggle',
	spendTargetContainer: '.js-spend-target__container',
	spendTargetInput: '.js-spend-target__input'
});

var module = {

	init: function () {

		$questionnaire = $('.js-questionnaire');

		Validate.init();

		module._initElements();
		module._initSubscriptions();
		module._initEvents();

		ToggleSet.init();
		Dependant.init($questionnaire);
	},

	_initElements: function () {

	},

	_openAfter: function (elm) {
		var afterId = $(elm._o.field).data('open-after');

		if (afterId !== '') {
			var afterPicker = document.getElementById(afterId);
		}
	},

	_initSubscriptions: function () {
		subscribe('/form/' + $questionnaire.attr('id') + '/valid', module._submitQuestionnaire);
	},

	_initEvents: function () {
		$questionnaire.on('customvalidation', module._customValidation);
		$questionnaire.on('change', selectors.spendTrigger, module._updateSpendAccess);
		$questionnaire.find(selectors.spendTrigger).trigger('change');
	},

	_customValidation: function (event) {
		var $form = $(this);
		var $departureInput = $('#DateDepart');
		var departureValue = $departureInput.val();

		if (departureValue !== '') {
			module._validateDepartureDate(departureValue, $departureInput);
			module._validateArrivalDate(departureValue);
			module._validateDependants($form, departureValue);
		}
	},

	_validateDepartureDate: function (departTime, $input) {
		var $dateDepartField = $input.closest('.js-validate-field');
		var data = {};

		if (new Date(departTime) >= new Date()) {
			data.valid = true;
		} else {
			data.valid = false;
			data.message = 'Depart date cannot be before today';
		}
		Validate.setErrorDisplay($dateDepartField, data);
	},

	_validateArrivalDate: function (departTime) {
		var $returnInput = $('#DateReturn');
		var $dateReturnField = $returnInput.closest('.js-validate-field');
		var data = {};

		if (new Date($returnInput.val()) >= new Date(departTime)) {
			data.valid = true;
		} else {
			data.valid = false;
			data.message = 'Return date cannot be before the departure date';
		}
		Validate.setErrorDisplay($dateReturnField, data);
	},

	_validateDependants: function ($form, departureDate) {
		var $allDependants = $form.find('.js-dependant');

		for (var i = 0; i < $allDependants.length; i++) {
			var $thisDependant = $allDependants.eq(i);
			var $datepicker = $thisDependant.find('.js-dependant__dob');

			if ($datepicker.val() !== '') {
				var date = new Date($datepicker.val());
				var data = {};

				if (moment(new Date(departureDate)).subtract(25, 'years').isBefore(date)) {
					data.valid = true;
				} else {
					data.valid = false;
					data.message = 'Dependants travelling with you must be younger than 25 on the day of departure';
				}
				Validate.setErrorDisplay($datepicker.closest('.js-validate-field'), data);
			}
		}
	},

	_submitQuestionnaire: function () {
		var data = $questionnaire.serialize();
		var dataArray = data.split('&');
		var quoteData = {};

		module._showLoadingButton($questionnaire);

		for (var i = 0; i < dataArray.length; i++) {
			var field = dataArray[i].split('=');
			quoteData[field[0]] = decodeURIComponent(field[1]);

			if (quoteData[field[0]].indexOf('+')) {
				quoteData[field[0]] = quoteData[field[0]].replace(/\+/g, ' ');
			}
		}

		// Regions
		var $regionChecks = $('[name="RegionNodeIds"]');
		var regions = [];

		for (var j = 0; j < $regionChecks.length; j++) {
			var $region = $regionChecks.eq(j);

			if ($region.is(':checked')) {
				regions.push($region.val());
			}
		}

		quoteData.RegionNodeIds = regions.join(',');

		// Card Holder
		quoteData.Customer = new Person(
			0,
			quoteData.DateOfBirth,
			quoteData.PermanentResident,
			quoteData.MedicalCoverRequired,
			quoteData.MedicalWaitingList,
			quoteData.MedicalAwaitingDiagnosis,
			quoteData.MedicalTreatmentOverseas,
			quoteData.MedicalScreeningComplete,
			quoteData.MedicalTerminalCondition
		);

		// Spouse
		if (quoteData.HasSpouse === 'true') {

			quoteData.Spouse = new Person(
				0,
				quoteData.SpouseDateOfBirth,
				quoteData.SpousePermanentResident,
				quoteData.SpouseMedicalCoverRequired,
				quoteData.SpouseMedicalWaitingList,
				quoteData.SpouseMedicalAwaitingDiagnosis,
				quoteData.SpouseMedicalTreatmentOverseas,
				quoteData.SpouseMedicalScreeningComplete,
				quoteData.SpouseMedicalTerminalCondition
			);
		}

		// Family Members
		var familyMembers = [];
		var $dependants = $('.js-dependant').filter(':visible');

		for (var k = 0; k < $dependants.length; k++) {
			var num = k + 1;

			var member = new Person(
				quoteData['ChildPersonId' + num],
				quoteData['ChildDateOfBirth' + num],
				quoteData['ChildPermanentResident' + num],
				quoteData['ChildMedicalCoverRequired' + num],
				quoteData['ChildMedicalWaitingList' + num],
				quoteData['ChildMedicalAwaitingDiagnosis' + num],
				quoteData['ChildMedicalTreatmentOverseas' + num],
				quoteData['ChildMedicalScreeningComplete' + num],
				quoteData['ChildMedicalTerminalCondition' + num]
			);
			familyMembers.push(member);
		}

		quoteData.FamilyMembers = familyMembers;

		var post = {
			type: 'POST',
			url: $questionnaire.attr('action'),
			data: JSON.stringify(quoteData),
			contentType: 'application/json',
			success: function(quote) {
				var url = quote.NextStepUrl;
				window.location = url;
			},
			error: function(error) {
				window.location = 'error';
			}
		};

		if ($questionnaire.is(':not([data-recaptcha-key=false])')) {
			var key = $questionnaire.attr('data-recaptcha-key');
			grecaptcha.ready(function() {
				grecaptcha.execute(key, { action: 'submit' }).then(function(token) {
					// Add your logic to submit to your backend server here.
					post.headers = {
						'g-Recaptcha-Response': token
					};
					$.ajax(post);
				});
			});
		} else {
			$.ajax(post);
		}
	},

	_updateSpendAccess: function (event) {
		var $trigger = $questionnaire.find(selectors.spendTrigger);
		var $container = $questionnaire.find(selectors.spendTargetContainer);
		var $input = $questionnaire.find(selectors.spendTargetInput);

		var shouldHideTarget = $trigger
			.find('option:selected')
			.attr('data-hide-spend')?.toLowerCase() === 'true';

		if (shouldHideTarget) {
			$container.hide();
			$input.val('');
		} else {
			$container.show();
		}
	},

	// Disabled submit button
	// Add loading text to button - this will be animated by CSS
	_showLoadingButton: function ($form) {
		var $submit = $form.find('.js-loader-btn');

		if ($submit.length) {
			$submit.html('<span>L</span><span>O</span><span>A</span><span>D</span><span>I</span><span>N</span><span>G</span>');
			$submit.attr('disabled', true);
		}
	}
};

var Questionnaire = {
	init: module.init
};

export { Questionnaire };