import $ from 'jquery';

var $controllers;

var module = {

	init: function () {
		module._initElements();
		module._initEvents();
	},

	_initElements: function () {
		$controllers = $('[data-toggle-controller]');
	},

	_initEvents: function () {
		$controllers.on('change', 'input', module.toggleController);
	},

	toggleController: function (event) {
		var $input = $(this);
		var target = $(event.delegateTarget).data('toggle-controller');

		if (typeof (target) !== 'undefined' && target !== '') {
			var $target = $('[data-toggle-item="' + target + '"]');
			var isInverted = $target.is('[data-toggle-invert="true"]');
			var valueTriggerShow = isInverted ? 'false' : 'true';

			if ($input.val() === valueTriggerShow) {
				$target.slideDown();
			} else {
				$target.slideUp();
				module.clearToggleData($target);
			}
		}
	},

	clearToggleData: function ($target) {
		var $inputs = $target.find('input');

		for (var i = 0; i < $inputs.length; i++) {
			var $input = $inputs.eq(i);
			var type = $input.attr('type');

			if (type === 'radio' || type === 'checkbox') {
				$input.removeAttr('checked');
			} else {
				$input.val('');
			}

			if (type === 'hidden') {
				$input.val('0');
			}
		}
	}
};

var ToggleSet = {
	init: module.init,
	toggleController: module.toggleController,
	clearToggleData: module.clearToggleData
};

export { ToggleSet };